import React from 'react';

import { EProductCardView, ProductCard } from './components/ProductCard';
import { TAction } from '../../../types';

type TProductGroup = {
  title?: string;
  products?: TAction[];
};
export type TProductsCardRowProps = {
  productsLeft?: TProductGroup;
  productsRight?: TProductGroup;
};

const ProductsCardRow: React.FC<TProductsCardRowProps> = ({
  productsLeft,
  productsRight,
}) => {
  const titleClass =
    'text-sm font-medium text-interface-700 flex gap-4 items-center whitespace-nowrap';
  const scrollWrapperClass =
    '-mx-4 max-w-[100vw] overflow-x-auto px-4 scrollbar-hidden';
  const divider = <hr className='w-full text-surface-200' />;
  return (
    <div className='mx-auto flex flex-col gap-6 px-4 desktop:w-full desktop:flex-row desktop:gap-4'>
      {productsLeft ? (
        <div className='flex flex-col gap-6 desktop:w-3/4'>
          <div className={titleClass}>
            {productsLeft.title}
            {divider}
          </div>
          <div className={scrollWrapperClass}>
            <div className='flex gap-4 max-tablet:after:min-w-px tablet:flex-wrap desktop:grid desktop:grid-cols-3'>
              {productsLeft.products?.map((action, index) => {
                const view =
                  index === 0
                    ? EProductCardView.Extended
                    : index === 6
                      ? EProductCardView.Button
                      : EProductCardView.Short;
                return <ProductCard view={view} key={index} link={action} />;
              })}
            </div>
          </div>
        </div>
      ) : null}
      {productsRight ? (
        <div className='flex flex-col gap-6 desktop:w-1/4'>
          <div className={titleClass}>
            {productsRight.title}
            {divider}
          </div>
          <div className={scrollWrapperClass}>
            <div className='flex gap-4 max-tablet:after:min-w-px tablet:flex-wrap'>
              {productsRight.products?.map((action, index) => (
                <ProductCard
                  view={EProductCardView.Short}
                  key={index}
                  link={action}
                />
              ))}
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default ProductsCardRow;
