import { ProductsCardRow } from '@front/shared/ds';
import { Section } from '@shared/master-types';
import React from 'react';

import { useActions } from '../../../features/actions/useActions.hook';

export type TProductCardRowConnectedProps = Extract<
  Required<Section>['rows'][number],
  { blockType: 'product-card-row' }
>;

const ProductCardRowConnected: React.FC<TProductCardRowConnectedProps> = ({
  productsLeft,
  productsRight,
}) => {
  const productsLeftPrepared = {
    title: productsLeft?.title,
    products: useActions(productsLeft?.links),
  };
  const productsRightPrepared = {
    title: productsRight?.title,
    products: useActions(productsRight?.links),
  };

  return (
    <ProductsCardRow
      productsLeft={productsLeftPrepared}
      productsRight={productsRightPrepared}
    />
  );
};

export default ProductCardRowConnected;
